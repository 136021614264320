@import url('../../../index.css');

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
        filter:blur(5px)
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    from {
        opacity: 0;
        transform: translateX(100%);
        filter:blur(5px)
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.PP .projectDiv {
    display: flex;
    flex-direction: row;
    margin: 3rem;
    width: 100%;
    height: 400px;
}


.PP .infoDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding-right: 2%;
}

.PP .imageDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width:50%;
}

.PP img {
    /* width:100%;
    height:90%; */
    cursor:none;
    object-fit:contain;
    height: 400px;
    width: auto;
}





.PP b {
    color:var(--primary-dark);
}

.PP .TILT {
    font-style: italic;
} 

.PP div > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: var(--primary-blue);
    color:white;
    border-radius:100px;
    padding:5px;
    margin-top: 2rem;
}

.PP div > a:hover {
    border:1px solid var(--primary-blue);
    background-color: white;
    color:var(--primary-blue);
}



/* image transition stuff */
.PP .projectDiv.show > .imageDiv {
    transform: translateX(0%);
    opacity: 1;
    transition: all 1s;
}

.PP .projectDiv.hidden > .imageDiv {
    transform: translateX(100%);
    opacity: 0;
    transition: all 1s;
}

/* info transition stuff */
.PP .projectDiv.show > .infoDiv {
    transform: translateX(0%);
    opacity: 1;
    transition: all 1s;
}

.PP .projectDiv.hidden > .infoDiv {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 1s;
}




/* carousel */


/* .projectDiv {
    margin: 20px 0;
}

.infoDiv {
    margin-bottom: 20px;
} */

/* .imageDiv {
    width: 100%;
    max-width: 600px;
    margin: auto;
} */

.mobilePP .projectDiv {
    display: flex;
    flex-direction: column;
    margin-bottom:15px;
    padding: 1rem;
    width: auto;
    height: auto;

}

.mobilePP .projectDiv:last-child {
    margin-bottom:0px;
}

.mobilePP .projectDiv:nth-child(even) {
    background-color: black;
}
.mobilePP .projectDiv:nth-child(even) h1 {
    color:white;
}
.mobilePP .projectDiv:nth-child(even) p {
    color:white;
}

.mobilePP a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    background-color: var(--primary-blue);
    color:white;
    border-radius:100px;
    padding:5px;
    margin-top: 1rem;
}

.mobilePP a:hover {
    border:1px solid var(--primary-blue);
    background-color: white;
    color:var(--primary-blue);
}

.mobilePP .projectDiv:nth-child(even) a:hover {
    border:1px solid var(--primary-blue);
    background-color: black;
    color:var(--primary-blue);
}

.mobilePP .imageDiv {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width:100%;
} 

.mobilePP .TILT {
    font-style: italic;
} 


