@import url('../../index.css');

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-120%)
    }
    to {
        transform: translateY(0%)
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0%)
    }
    to {
        transform: translateY(-120%)
    }
}


.mobileNavbar ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.mobileNavbar li {
    margin: 1rem;
    font-weight: 700;
    font-size: xxx-large;
    color: white;
    animation: fadeIn 2.4s ease-in forwards;
}

.mobileNavbar li:hover {
    color:black;
    cursor: pointer;
}

.mobileNavbar .link {
    text-decoration: none;
    color: inherit;
}

.mobileNavbar .navbarOptions {
    transform: translateY(-110%)
}

.mobileNavbar .show {
    animation: slideIn .4s ease-in forwards;
}

.mobileNavbar .hide {
    animation: slideOut .4s ease-in forwards;
}

