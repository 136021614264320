.CP {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: calc(100vh - 181px)
    /* footer is 101px, navbar is 80px */
}


.CP .contactContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: flex-start;
    align-items: flex-start;
    
}

.CP .contactContainer div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.CP .infoContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: flex-start;
    align-items: center;
}

.CP .infoContainer p {
    padding:1rem;
    background-color: var(--primary-light);
    border-radius: 10px;
}

.CP h1 {
    color: black;
    font-weight: 400;
    padding: 1rem;
    margin: 1rem;
    background-color: var(--primary-light);
    border-radius: 100px;
    font-size: xx-large;
}

.CP a {
    color: black;
}

.CP a:hover {
    color: var(--primary-blue);
    text-decoration: underline;
}

.CP h1:hover {
    background-color: white;
}




/* Mobile from here down */

.mobileCP .linkContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-left:4%;
}

.mobileCP .linkContainer h1{
    margin-right:10px;
}

.mobileCP a {
    color: black;
}

.mobileCP a:hover {
    color: var(--primary-blue);
    text-decoration: underline;
}

.mobileCP h1:hover {
    background-color: white;
}

.mobileCP .infoContainer {
    margin:10px;
}

.mobileCP .infoContainer p {
    background-color: var(--primary-light);
    padding:5px;
    border-radius: 10px;
}