
:root {
  --primary-dark: #686868;
  --primary-light: #f0f0f0;
  --primary-blue: #007AFF;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
}

.container {
  max-width: 1240px;
  margin: auto;
  padding: 0 1rem;
}