@keyframes rotateIcon {
    from {
        transform: translateX(0) rotate(0deg);
    }
    to {
        transform: translateX(15px) rotate(45deg);
    }
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes slideToLeft{
    from {
        opacity: 0;
        transform: translateX(0);
    }
    to {
        opacity: 1;
        transform: translateX(-100%);
    }
}

.hand {
    animation: wave .5s forwards;
}

.hand:hover {
    animation: wave .5s forwards;
}

#contentContainer {
    /* width:1100px;  */
    max-width:1240px;
    /* min-width:940px; */
    position:'relative';
}

.contactDiv {
    position:fixed;
    width:50px;
    height:180px;
    top:40px;
    right:40px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.iconDiv {
    width:100%;
    height:50px;
    position:relative;
    display: flex;
    flex-direction: row;
}

.contactIcon {
   position:absolute;
   top: 0;
   left: 0;
   z-index: 11;
}

.contactIcon:hover {
    animation: rotateIcon .2s forwards;
    cursor: pointer;
}

.hoverText {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    opacity:0;

}

.contactIcon:hover + .hoverText {
    opacity:1;
    animation: slideToLeft .2s forwards;
}

.bioContainer {
    height:auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:40px;
    padding-top: 80px;
    /* padding-bottom: 80px; */
    /* background-color: aqua; */
}

.profPicDiv {
    width: 25%;
    aspect-ratio: 1;
    margin-right: 20px;
}

.profPic {
    max-width: 100%;
    max-height: 100%;
    /* width: auto; */
}

.bioDiv {
    width:60%;
}

h1 {
    margin-bottom: 1.5rem;
}

/* h2 {
    margin-bottom: 1.5rem;
} */

.languagesContainer {
    height:500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding:40px;
}

.languageInfo {
    width: 40%;
}

.languageGrid {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: 20%, 20%, 20%, 20%, 20%;
    place-items: center;
    flex:1;
    height: 100%;
    margin-left: 20px;
    column-gap: 4%;
}

.languageItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px;
    padding-right: 15%;
    padding-left: 15%;
}

/* .languageItem:hover {
    border: 2px solid #d2d2d2
} */

.projectsContainer {
    height: 150;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.carouselDiv {
    height:450px;
    width:100%;
}


.projectSlide {
    width: 700px;
    height: 400px;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding:40px;
    display: flex;
    flex-direction: row;
}

.projectSlide:hover {
    cursor: pointer;
    border: 2px solid grey;
}

.projectSlideInfo {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.projectSlideInfo a {
    color: #f2f2f2;
    background-color: black;
    padding:.5rem;
    border-radius: 100px;
}

.projectSlideInfo a:hover {
    background-color: #f2f2f2;
    color: black;
    border: 1px solid black;
}

.projectSlideImages {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.projectPopup {
    position: fixed;
    top: calc(50% - 300px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    background-color: #f2f2f2;
    width: 900px;
    height: 600px;
    border: 2px solid #a2a2a2;
    border-radius: 10px;
}

.infoScroller {
    flex:1;
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
}

.projectPopup img:hover{
    cursor: -moz-zoom-in; 
    cursor: -webkit-zoom-in; 
    cursor: zoom-in;
}

.projectSlideInfo ::-webkit-scrollbar {
    display: none;
}

.mobileProjectDiv {
    display: none;
}

.closeIcon:hover {
    cursor: pointer;
}

#largeImage {
    cursor: -moz-zoom-out; 
    cursor: -webkit-zoom-out; 
    cursor: zoom-out;
}

.largeImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    position: fixed;
    width: 880px;
    height: 520px;
    top: calc(50% - 240px);
    left: calc(50% - 440px);
}

.hidden {
    display: none;
}

@media (max-width: 900px) {

    h1 {
        line-height: 90%;
    }

    h2 {
        line-height: 95%;
    }

    .contactDiv {
        position:absolute;
        width:50px;
        height:180px;
        top:50px;
        right:15px;
        z-index: 10;
    }

    .bioContainer {
        padding:10px;
        padding-top: 60px;
        padding-bottom: 60px;
        flex-direction: column;
    }

    .profPicDiv  {
        width:60%;
        margin-bottom: 60px;
    }

    .bioDiv {
        width:100%;
    }

    .languagesContainer {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top:60px;
        padding:20px;
    }
    
    .languageInfo {
        width: 100%;
        margin-bottom: 20px;
    }

    .languageGrid {
        display: grid;
        grid-template-columns: 48% 48%;
        grid-template-rows: 20%, 20%, 20%, 20%, 20%;
        place-items: center;
        flex:1;
        height: 100%;
        width: 100%;
        margin-left:0px;
    }

    .languageItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 10px;
        padding: 10px;
        margin-bottom:10px;
    }

    .languageItem img {
        display: none;
    }
    
    .languageItem img:last-child {
        display: block;
    }

    .carouselDiv {
        display: none;
    }
    
    .projectsContainer {
        padding-left: 10px;
    }

    .mobileProjectDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .mobileProjectSlide {
        /* width: 375px; */
        padding: 20px;
        /* background-color: aqua; */
        margin-bottom: 2rem;
        background-color: #f2f2f2;
        border-radius: 10px;
    }

    .mobileProjectSlide h2 {
        margin-bottom: .5rem;
    }

    .mobileProjectDiv a {
        color: #f2f2f2;
        background-color: black;
        padding:.5rem;
        border-radius: 100px;
    }

    .mobileCarouselContainer {
        /* width: 300px; */
        margin-top: 2rem;
    }

    .projectPopup {
        display: none;
    }
    
    .largeImageContainer {
        display: none;
    }
}