@import url('../../../index.css');

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
        /* filter:blur(5px) */
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.AP .bioContainer {
    margin:2rem;
    display: flex;
    flex-direction: row;
    width:100%;
}

.AP .imageDiv {
    width: 50%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.AP .bioDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    animation: slideInFromLeft 1s ease-out forwards;
}

.AP .languageContainerOuter {
    display:'flex'; 
    direction:'column'; 
    width:'100%';
}

.AP .languageContainerInner {
    display:'flex'; 
    flex-direction:'row'; 
    justify-content:'flex-start';
    width:'100%';
}

.AP .languageDiv {
    display: flex;
    flex-direction: column;
    width:50%;
}

.AP .languageDiv h2 {
    padding: 5px;
    margin-bottom: .5rem;
    color: var(--primary-dark)
}

.AP .hobbies:hover {
    cursor:default;
}


.AP .motorcycleDiv {
    height: 3000px;
    width: 100%;
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.AP .hobbyContainer {
    width: 100%;
    height: 550px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.AP p a {
    color: var(--primary-blue)
}

.AP p a:hover {
    text-decoration: underline;
}

.AP .hobbyInfoDiv {
    width: 56%;
    margin-left:2%;
    margin-right:2%;
    padding:10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid var(--primary-blue); */
    border-radius: 10px;
    background-color: var(--primary-light);
}

.AP .photoShopImageDiv {
    height:'100%';
    position: relative;
    display: flex;
    flex-direction: column;
}

.AP img:hover {
    cursor:default;
}

.AP .mapContainer {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AP .imageRow {
    width: 100%;
    height: 35%;
    padding: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.AP .imageRow img {
    max-width: 100%;
    max-width: 100%;
    width: auto;
}

.AP .imageRow img:hover {
    max-width: 120%;
    max-width: 120%;
    width: auto;
    cursor:pointer;
}


.AP .imageRow div {
    width: 25%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.AP .vehicleImage {
    max-width:'100%';
    max-height:'100%';
    width:auto;
}

/* Mobile stuff from here down */
.mobileAP {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mobileBio {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:20px;
    margin-bottom: 2rem;
}



.mobileHobbyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding:20px;
    padding-top:0px;
    margin-top: 3rem;
    /* background-color: aqua; */
}

.mobileImageRow {
    width: 100%;
    padding: 1%;
    display: flex;
    flex-direction: row;
}

.mobileImageRow div {
    width: 50%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileImageRow img {
    max-width: 90%;
    max-height: 90%;
    width: auto;
}

.mobileAP p a {
    color: var(--primary-blue)
}

.mobileAP p a:hover {
    text-decoration: underline;
}

.mobileAP h1 {
    line-height: 100%;
    margin-bottom:1rem;
}