@import url('../../index.css');

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    background: linear-gradient(to right, var(--primary-dark), rgba(214, 214, 214, 0));
    animation: fadeIn 1.5s ease-in forwards;
}

.navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.navbar li {
    margin: 2rem;
    font-weight: 600;
    font-size: larger;
    color: white;
    animation: fadeIn 3s ease-in forwards;
}

.navbar li:hover {
    color:black;
    cursor: pointer;
}

.navbar .link {
    text-decoration: none;
    color: inherit;
}